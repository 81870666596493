@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url('./fonts/Inter-ExtraLight-slnt=0.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url('./fonts/Inter-Regular-slnt=0.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter-Bold-slnt=0.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: local('Neue Haas Grotesk Display Pro'),
    url('./fonts/Linotype - Neue Haas Grotesk Display Pro.ttf')
      format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: local('Neue Haas Grotesk Display Pro'),
    url('./fonts/NeueHaasDisplayMediu.ttf') format('truetype');
  font-weight: 500;
}

 @font-face {
  font-family: "DM Sans";
  src: local("DM Sans"), url("./fonts/DMSans-Regular.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: local("DM Sans"), url("./fonts/DMSans-Regular.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter-Black-slnt=0.ttf') format('truetype');
  font-weight: 900;
}

.center {
  position: absolute;
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -75px;
}

.main-content {
  display: flex;
  flex-direction: column;
  margin: 0px auto 0px auto;
}

@media only print {
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-weight: 200;
    font-size: 20px;
  }
}

* {
  margin: 0;
  padding: 0;
  font-size: 20px;
  scroll-padding-top: 40px;
}

b {
  font-weight: 700;
  font-size: inherit;
}

i {
  font-size: inherit;
}

u {
  font-size: inherit;
}

span {
  font-size: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

button {
  outline: 0 !important;
}

body {
  background-color: #edeff3 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

a {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

h2 {
  font-size: 22px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

h1 {
  font-size: 36px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

ul {
  margin-bottom: 5px;
  margin-left: 20px;
}

.Toastify__toast-container--top-right {
  top: 60px !important;
}

.Toastify__progress-bar--error {
  background-color: #ff0a0a !important;
}

.Toastify__toast--error {
  background: #ffcece !important;
}

.Toastify__progress-bar--warning {
  background-color: #ffcc00 !important;
}

.Toastify__toast--warning {
  background: #fff7d8 !important;
}

.Toastify__progress-bar--success {
  background-color: #21ca1e !important;
}

.Toastify__toast--success {
  background: #daf9da !important;
}

.Toastify__progress-bar--info {
  background-color: #5196ff !important;
}

.Toastify__toast--info {
  background: #c7ddff !important;
}

.Toastify__close-button {
  color: rgba(0, 0, 0, 0.6) !important;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}
